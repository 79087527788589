import { type Price } from '@commercetools/platform-sdk/dist/commercetools-platform-sdk.cjs'
import { useLoaderData } from '@remix-run/react'
import { useTranslation } from 'react-i18next'
import PlentyBody from '#app/components/typography/plenty-body'
import { arePricesEqual } from '#app/features/pdp/components/pdp-price-tag'

import { type loader } from '#app/routes/_public+/products+/$slug'
import { moneyFormatWithTypedMoney } from '#app/utils/money'
import { type StyleVariant } from '#types/product'

type PriceTagProps = {
	activeStyleVariant: StyleVariant
	price: Price
}

export const PriceTag = ({ activeStyleVariant, price }: PriceTagProps) => {
	const { storeParams } = useLoaderData<typeof loader>()
	const isDiscounted = Boolean(price?.discounted?.value)
	const priceToShow = isDiscounted ? price?.discounted?.value : price?.value

	let { t } = useTranslation('product_details_page')
	// If neither discounted nor original price is available, show a fallback message
	if (!priceToShow) {
		return <div>Price not available</div>
	}

	// Format the displayed price and the original price (if discounted) for display
	const formattedPriceToShow = moneyFormatWithTypedMoney(priceToShow)
	const formattedOriginalPrice = isDiscounted
		? moneyFormatWithTypedMoney(price.value)
		: null

	let channelFromStoresParam: string | undefined = undefined

	if (storeParams?.length) {
		channelFromStoresParam = activeStyleVariant.variants[0].availability?.find(
			it => it.storeKey && storeParams.includes(it.storeKey),
		)?.channelKey
		let storePrice
		if (price.channel?.id === channelFromStoresParam) storePrice = price
		else {
			storePrice = activeStyleVariant.variants[0].prices?.find(
				price => price.channel?.id === channelFromStoresParam,
			)
		}

		price = storePrice || price // Fallback to the generic price if no specific store price is found
	}
	const isEqualPrice = arePricesEqual(activeStyleVariant.variants, undefined)
	return (
		<div className="flex items-center md:space-x-4">
			<span>
				<PlentyBody
					size="md"
					className={`text-${
						isDiscounted ? 'text-red' : 'text-beige-100'
					} font-${isDiscounted ? 'bold' : 'normal'}`}
				>
					{!isEqualPrice && <span>{t('from_label') + ' '}</span>}
					{formattedPriceToShow}
				</PlentyBody>
			</span>
			{isDiscounted && formattedOriginalPrice && (
				<span className="hidden text-green-40 line-through md:inline">
					<PlentyBody size="sm" className="text-green-40">
						{formattedOriginalPrice}
					</PlentyBody>
				</span>
			)}
		</div>
	)
}
