import { type Price } from '@commercetools/platform-sdk'
import { useTranslation } from 'react-i18next'
import PlentyBody from '#app/components/typography/plenty-body'
import { type StyleVariant } from '#types/product'

interface ProductCardLabelProps {
	activeStyleVariant: StyleVariant // Define StyleVariant type as per your application
	price: Price
}

const ProductCardLabel: React.FC<ProductCardLabelProps> = ({
	activeStyleVariant,
	price,
}) => {
	const isDiscounted =
		price && price.discounted && price.discounted.discount.obj?.isActive
	let { t } = useTranslation('footer')

	let discountPercentage = 0
	if (isDiscounted) {
		const discountType = price.discounted?.discount.obj?.value.type

		if (
			discountType === 'relative' &&
			price.discounted?.discount.obj?.value.permyriad
		) {
			discountPercentage = price.discounted?.discount.obj?.value.permyriad / 100
		} else if (
			isDiscounted &&
			price.discounted?.discount.obj?.value.type === 'absolute'
		) {
			discountPercentage =
				price.discounted?.discount.obj?.value.money[0].centAmount
		}
	}

	// const stockQuantity = activeStyleVariant
	// 	? activeStyleVariant.variants.reduce((sum, variant) => {
	// 			const channelSum = variant.availability
	// 				? variant.availability.reduce(
	// 						(channelSum, channel) =>
	// 							channelSum + (channel.availableQuantity || 0),
	// 						0,
	// 				  )
	// 				: 0

	// 			return sum + channelSum
	// 	  }, 0)
	// 	: 0

	// const isOnStock = stockQuantity > 0
	// const isLowInStock = stockQuantity > 0 && stockQuantity < 3
	return (
		<>
			{isDiscounted && (
				<div className="absolute bottom-4 left-0 z-20 rounded-r-lg bg-mint-green-50 px-4 py-1 md:bottom-auto md:top-4">
					<PlentyBody size="xs">
						{t('deal_off', {
							discountPercentage: discountPercentage,
						})}
					</PlentyBody>
				</div>
			)}

			{/* Hidden for now since we got no stock */}
			{/* {isLowInStock && (
				<div
					className={`absolute left-0 ${
						isDiscounted ? 'top-14' : 'top-4'
					} z-50 rounded-r-lg bg-yellow-200 px-4 py-1`}
				>
					<PlentyBody  size="xs" >Low in Stock</PlentyBody>
				</div>
			)} */}
		</>
	)
}

export default ProductCardLabel
