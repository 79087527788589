import { useTranslation } from 'react-i18next'
import { Icon } from '#app/components/ui/icon'
import {
	Tooltip,
	TooltipArrow,
	TooltipContent,
	TooltipProvider,
	TooltipTrigger,
} from '#app/components/ui/tooltip'

const InformationTooltip = () => {
	let { t } = useTranslation('shopping_bag')
	
	return (
		<TooltipProvider>
			<Tooltip>
				<TooltipTrigger asChild>
					<div data-state="closed" className="cursor-pointer">
						<Icon name="info" size="lg" />
					</div>
				</TooltipTrigger>

				<TooltipContent
					className="rounded-sm border border-beige-100 bg-white px-4 py-2 shadow-lg"
					sideOffset={5}
				>
					{t('prices_can_vary')}
					<TooltipArrow className="h-2 w-4 translate-y-[-1px] fill-white stroke-beige-100 " />
				</TooltipContent>
			</Tooltip>
		</TooltipProvider>
	)
}

export default InformationTooltip
